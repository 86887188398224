import React from 'react'

import Button from '../../UI/Button/Button'
import classes from './OrderSummary.module.css'

const orderSummary = (props) => {
    const ingredientSummary = Object.keys(props.ingredients).map((igKey) => {
        return (
            <li key={igKey}>
                <span style={{ textTransform: 'capitalize' }}>{igKey}</span>:{' '}
                {props.ingredients[igKey]}
            </li>
        )
    })

    return (
        <div className={classes.OrderSummary}>
            <h2>Order summary</h2>
            <p>A delicious burger with the following ingredients:</p>
            <ul>{ingredientSummary}</ul>
            <p style={{ fontWeight: 600 }}>
                Total Price: ${props.price.toFixed(2)}
            </p>

            <Button btnType='Danger' clicked={props.purchaseCancelled}>
                CANCEL
            </Button>
            <Button btnType='Success' clicked={props.purchaseContinued}>
                CONTINUE
            </Button>
        </div>
    )
}

export default orderSummary
