import { React, Component } from 'react'
import Order from '../../components/Order/Order'
import axios from '../../axios-orders'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import * as actions from '../../store/actions/index'
import { connect } from 'react-redux'
import Spinner from '../../components/UI/Spinner/Spinner'
import Modal from '../../components/UI/Modal/Modal'

class Orders extends Component {
    state = { showDetailsFlag: false, showDetailsId: null }

    componentDidMount() {
        this.props.onFetchOrders(this.props.authToken, this.props.userId)
    }

    showDetailsHandler = (id) => {
        this.setState({ showDetailsId: id, showDetailsFlag: true })
    }

    hideDetailsHandler = () => {
        this.setState({ showDetailsFlag: false })
    }

    render() {
        let orders = <Spinner />

        // let prevOrderDateArray = this.props.beOrders.map((item) =>
        //     Date.parse(item.orderDate)
        // )
        // prevOrderDateArray.sort((a, b) => a < b)
        // console.log(prevOrderDateArray)

        if (!this.props.loading) {
            orders = this.props.beOrders.map((item) => (
                <Order
                    key={item.id}
                    ingredients={item.ingredients}
                    price={+item.price}
                    date={item.orderDate}
                    clicked={() => this.showDetailsHandler(item.id)}
                    selected={item.id === this.state.showDetailsId}
                />
            ))
        }

        // if (!this.props.loading) {
        //     orders = this.props.beOrders.map((item) => (
        //         <Order
        //             key={item.id}
        //             ingredients={item.ingredients}
        //             price={+item.price}
        //             date={item.orderDate}
        //             clicked={() => this.showDetailsHandler(item.id)}
        //             selected={item.id === this.state.showDetailsId}
        //         />
        //     ))
        // }

        let detailInfo = 'Select an order to show details'
        if (this.state.showDetailsId) {
            detailInfo = this.props.beOrders.map((item) => {
                return item.id === this.state.showDetailsId
                    ? Object.keys(item.orderData).map((detailKey) => {
                          //   console.log(item.id)
                          return (
                              <div
                                  key={item.id + detailKey}
                                  style={{
                                      paddingBottom: '10px',
                                      fontSize: 'small',
                                  }}>
                                  <span
                                      style={{
                                          textTransform: 'capitalize',
                                      }}>
                                      {detailKey} :
                                  </span>
                                  <span
                                      style={{
                                          float: 'right',
                                          color: 'blue',
                                      }}>
                                      {item.orderData[detailKey]}
                                  </span>
                              </div>
                          )
                      })
                    : null
            })
        }
        // () => {this.props.beOrders.map((item) => {
        //     detailInfo =
        //         item.id === this.state.showDetailsId ? item.orderData : null
        // })}
        // if (this.state.showDetailsId) {
        //     detailInfo = this.props.beOrders.map((item) => {
        //         console.log(item)
        // if (item.id === this.state.showDetailsId) {

        // }
        // })
        // console.log(this.props.beOrders[this.state.showDetailsId])
        // details = this.props.beOrders[this.state.showDetailsId]

        return (
            <div>
                <h2 style={{ textAlign: 'center' }}>Prior Orders</h2>
                {orders}
                <Modal
                    show={this.state.showDetailsFlag}
                    modalClosed={this.hideDetailsHandler}>
                    <div style={{ marginBottom: '15px' }}>
                        <h4
                            style={{
                                marginTop: '10px',
                                marginBottom: '0px',
                            }}>
                            Order details
                        </h4>
                        <p
                            style={{
                                // textAlign: 'right',
                                marginTop: '2px',
                                marginBottom: '6px',
                                color: 'blue',
                                fontSize: '10px',
                            }}>
                            {this.state.showDetailsId}
                        </p>
                        <hr style={{ borderWidth: '0.5px' }} />
                    </div>
                    {detailInfo}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.ord.loading,
        beOrders: state.ord.orders,
        authToken: state.auth.tokenId,
        userId: state.auth.userId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchOrders: (token, userId) =>
            dispatch(actions.fetchOrders(token, userId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(Orders, axios))
