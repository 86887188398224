import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Input from '../../components/UI/Input/Input'
import Button from '../../components/UI/Button/Button'
import Spinner from '../../components/UI/Spinner/Spinner'
import classes from './Auth.module.css'
import * as actions from '../../store/actions/index'
import { updateObject, checkValidity } from '../../shared/utility'

class Auth extends Component {
    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email address',
                    label: 'Email',
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                    regexp: /^[A-Z0-9+_.-]+@[A-Z0-9.-]+[.][A-Z]{2,3}$/i,
                    searchFlag: false,
                },
                valid: false,
                touched: false,
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password',
                    label: 'Password',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6,
                },
                valid: false,
                touched: false,
            },
        },
        formIsValid: false,
        // loading: false,
        show: true,
        isSignup: false,
        showAuthStatus: false,
    }
    componentDidMount() {
        if (
            !this.props.buildInProgress &&
            this.props.authRedirectPath !== '/'
        ) {
            this.props.onResetAuthRedirectPath()
        }
    }

    inputChangedHandler = (event, controlId) => {
        const updatedControls = updateObject(this.state.controls, {
            [controlId]: updateObject(this.state.controls[controlId], {
                value: event.target.value,
                valid: checkValidity(
                    event.target.value,
                    this.state.controls[controlId].validation
                ),
                touched: true,
            }),
        })

        let formDataValid = true
        for (let index in updatedControls) {
            formDataValid = updatedControls[index].valid && formDataValid
        }

        this.setState({
            controls: updatedControls,

            // formIsValid: formDataValid,
            formIsValid: true, // Set to true explicitly so invalid submissions can be tested ...
        })
    }

    submitHandler = (event) => {
        event.preventDefault()
        this.props.onAuth(
            this.state.controls.email.value,
            this.state.controls.password.value,
            this.state.isSignup
        )
        this.setState({ showAuthStatus: true })

        this.timerID = setTimeout(() => {
            this.setState(() => ({ showAuthStatus: false }))
        }, 5000)
    }

    // Clear async timer before unmounting ...
    componentWillUnmount() {
        clearTimeout(this.timerID)
    }

    switchAuthModeHandler = () => {
        this.setState((prevState) => {
            return { isSignup: !prevState.isSignup }
        })
    }

    render() {
        const formElementsArray = []

        for (let index in this.state.controls) {
            formElementsArray.push({
                id: index,
                config: this.state.controls[index],
            })
        }

        let form = (
            <form>
                {formElementsArray.map((mapIndex) => (
                    <Input
                        key={mapIndex.id}
                        elementType={mapIndex.config.elementType}
                        elementConfig={mapIndex.config.elementConfig}
                        value={mapIndex.config.value}
                        invalid={!mapIndex.config.valid}
                        shouldValidate={mapIndex.config.validation}
                        touched={mapIndex.config.touched}
                        changed={(event) =>
                            this.inputChangedHandler(event, mapIndex.id)
                        }
                        label={mapIndex.config.elementConfig.label}
                    />
                ))}
            </form>
        )
        if (this.props.loading) {
            form = <Spinner />
        }

        const headlineMsg = this.state.isSignup
            ? 'User registration'
            : 'User login'

        let responseStatusMsg = null
        if (this.state.showAuthStatus) {
            responseStatusMsg = this.props.error ? (
                <p className={classes.ErrorMsg}>
                    Status fail ... {this.props.error.message}
                </p>
            ) : null
        }

        let authRedirect = null
        if (this.props.authFlag) {
            authRedirect = <Redirect to={this.props.authRedirectPath} />
        }

        return (
            <div className={classes.Auth}>
                {authRedirect}
                <h4>{headlineMsg}</h4>
                {form}
                {responseStatusMsg}
                <Button
                    btnType='Info'
                    disabled={false}
                    clicked={this.switchAuthModeHandler}>
                    Switch to {this.state.isSignup ? 'Log in' : 'Register'}
                </Button>
                {/* <Button btnType='Success' disabled={!this.state.formIsValid}> */}
                <Button
                    btnType='Danger'
                    disabled={false}
                    clicked={this.submitHandler}>
                    Submit
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state)

    return {
        loading: state.auth.loading,
        error: state.auth.error,
        authFlag: state.auth.tokenId !== null,
        buildInProgress: state.bb.buildInProgress,
        authRedirectPath: state.auth.authRedirectPath,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (email, password, signupFlag) =>
            dispatch(actions.auth(email, password, signupFlag)),
        onResetAuthRedirectPath: () =>
            dispatch(actions.setAuthRedirectPath('/')),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
