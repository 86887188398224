import React from 'react'

import classes from './NavigationItems.module.css'
import NavigationItem from './NavigationItem/NavigationItem'

const navigationItems = (props) => (
    <ul className={classes.NavigationItems}>
        <NavigationItem link='/' exactMatch>
            Burger Builder
        </NavigationItem>
        {props.authFlag ? (
            <NavigationItem link='/orders'>Orders</NavigationItem>
        ) : null}
        {props.authFlag ? (
            <NavigationItem link='/logout'>Logout</NavigationItem>
        ) : (
            <NavigationItem link='/auth'>Authenticate</NavigationItem>
        )}
    </ul>
)

export default navigationItems
