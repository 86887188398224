import * as actionTypes from './actionTypes'
import axios from '../../axios-orders'

export const addIngredient = (ingredient) => {
    return {
        type: actionTypes.ADD_INGREDIENT,
        ingredientName: ingredient,
    }
}
export const removeIngredient = (ingredient) => {
    return {
        type: actionTypes.REMOVE_INGREDIENT,
        ingredientName: ingredient,
    }
}

export const initIngredients = () => {
    return (dispatch) => {
        axios
            .get('/ingredients.json')
            .then((response) => {
                // console.log(response)
                dispatch(setIngredients(response.data))
            })
            .catch(() => {
                dispatch(fetchIngredientsFailed())
            })
    }
}
export const setIngredients = (ingredients) => {
    return {
        type: actionTypes.SET_INGREDIENTS,
        ingredients: ingredients,
    }
}
export const fetchIngredientsFailed = () => {
    return {
        type: actionTypes.FETCH_INGREDIENTS_FAILED,
    }
}
export const resetAll = () => {
    return {
        type: actionTypes.RESET_ALL,
    }
}
