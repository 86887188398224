import React from 'react'
import classes from './Spinner.module.css'

const spinner = () => (
    <React.Fragment>
        <div className={classes.Loader}>Loading...</div>
        <h3 style={{ textAlign: 'center' }}> Connecting with backend ...</h3>
    </React.Fragment>
)

export default spinner
