import React, { Component } from 'react'
import { connect } from 'react-redux'

import Burger from '../../components/Burger/Burger'
import BuildControls from '../../components/Burger/BuildControls/BuildControls'
import Modal from '../../components/UI/Modal/Modal'
import OrderSummary from '../../components/Burger/OrderSummary/OrderSummary'
import Spinner from '../../components/UI/Spinner/Spinner'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import axios from '../../axios-orders'
import * as actions from '../../store/actions/index'

export class BurgerBuilder extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {...}
    // }
    state = {
        // ingredients: null,
        // totalPrice: 4,
        purchasable: false,
        purchasing: false,
    }

    componentDidMount() {
        // console.log(this.props)
        // this.props.onReset()
        this.props.onInitIngredients()
    }

    // componentDidUpdate() {
    //     console.log(this.props.ingr)
    // }

    updatePurchaseState(ingredients) {
        const sum = Object.keys(ingredients)
            .map((igKey) => {
                return ingredients[igKey]
            })
            .reduce((sum, el) => {
                return sum + el
            }, 0)
        // this.setState({ purchasable: sum > 0 })
        return sum > 0
    }

    // addIngredientHandler = (type) => {
    // const oldCount = this.state.ingredients[type]
    // const updatedCount = oldCount + 1
    // const updatedIngredients = {
    //     ...this.state.ingredients,
    // }
    // updatedIngredients[type] = updatedCount
    // const priceAddition = INGREDIENT_PRICES[type]
    // const oldPrice = this.state.totalPrice
    // const newPrice = oldPrice + priceAddition
    // this.setState({ totalPrice: newPrice, ingredients: updatedIngredients })
    // this.setState({ totalPrice: newPrice })
    // this.updatePurchaseState(updatedIngredients)
    // this.updatePurchaseState(this.props.ingr)
    // }

    // removeIngredientHandler = (type) => {
    // const oldCount = this.state.ingredients[type]
    // if (oldCount <= 0) {
    //     return
    // }
    // const updatedCount = oldCount - 1
    // const updatedIngredients = {
    //     ...this.state.ingredients,
    // }
    // updatedIngredients[type] = updatedCount
    // const priceDeduction = INGREDIENT_PRICES[type]
    // const oldPrice = this.state.totalPrice
    // const newPrice = oldPrice - priceDeduction
    // this.setState({ totalPrice: newPrice, ingredients: updatedIngredients })
    // this.setState({ totalPrice: newPrice })
    // this.updatePurchaseState(this.props.ingr)
    // }

    purchaseHandler = () => {
        if (this.props.authFlag) {
            this.setState({ purchasing: true })
        } else {
            this.props.onSetAuthRedirectPath('/checkout')
            this.props.history.push({
                pathname: '/auth',
            })
        }
    }

    purchaseCancelHandler = () => {
        this.setState({ purchasing: false })
    }

    purchaseContinueHandler = () => {
        // const queryParams = []
        // console.log(this.state.ingredients)
        // for (let i in this.props.ingr) {
        // console.log(i, this.state.ingredients[i])
        //     queryParams.push(
        //         encodeURIComponent(i) +
        //             '=' +
        //             encodeURIComponent(this.props.ingr[i])
        //     )
        // }
        // queryParams.push('price=' + this.props.price)
        // console.log(`queryParams = ${queryParams}`)
        // console.log(queryParams)
        // const queryString = queryParams.join('&')
        // console.log(`queryString = ${queryString}`)
        // console.log(queryString)
        this.props.onInitPurchase()
        this.props.history.push({
            pathname: '/checkout',
            // search: '?' + queryString,
        })
        // console.log(this.props.history)
    }

    render() {
        const disabledInfo = {
            ...this.props.ingr,
        }
        for (let key in disabledInfo) {
            disabledInfo[key] = disabledInfo[key] <= 0
        }

        let orderSummary = null

        let burger = this.props.err ? (
            <p
                style={{
                    textAlign: 'center',
                    margin: '150px auto',
                    color: 'darkred',
                }}>
                Ingredients can't be loaded!
            </p>
        ) : (
            <Spinner />
        )

        if (this.props.ingr) {
            burger = (
                <React.Fragment>
                    <Burger ingredients={this.props.ingr} />
                    <BuildControls
                        ingredientAdded={this.props.onIngredientAdded}
                        ingredientRemoved={this.props.onIngredientRemoved}
                        disabled={disabledInfo}
                        purchasable={this.updatePurchaseState(this.props.ingr)}
                        ordered={this.purchaseHandler}
                        price={this.props.price}
                        isAuthenticated={this.props.authFlag}
                    />
                </React.Fragment>
            )
            orderSummary = (
                <OrderSummary
                    ingredients={this.props.ingr}
                    price={this.props.price}
                    purchaseCancelled={this.purchaseCancelHandler}
                    purchaseContinued={this.purchaseContinueHandler}
                />
            )
        }

        // if (this.state.loading) {
        //     orderSummary = <Spinner />
        //

        // {salad: true, meat: false, ...}
        return (
            <React.Fragment>
                <Modal
                    show={this.state.purchasing}
                    modalClosed={this.purchaseCancelHandler}>
                    {orderSummary}
                </Modal>
                {burger}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ingr: state.bb.ingredients,
        price: state.bb.totalPrice,
        err: state.bb.error,
        authFlag: state.auth.tokenId !== null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onIngredientAdded: (changedIngredient) =>
            dispatch(actions.addIngredient(changedIngredient)),

        onIngredientRemoved: (changedIngredient) =>
            dispatch(actions.removeIngredient(changedIngredient)),

        onInitIngredients: () => dispatch(actions.initIngredients()),

        onInitPurchase: () => dispatch(actions.purchaseInit()),
        onSetAuthRedirectPath: (path) =>
            dispatch(actions.setAuthRedirectPath(path)),
        onReset: () => dispatch(actions.resetAll()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(BurgerBuilder, axios))
