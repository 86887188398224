import * as actionTypes from './actionTypes'
import axios from 'axios'

export const authStart = () => {
    // console.log('authStart initiated ...')
    return {
        type: actionTypes.AUTH_START,
    }
}
export const authSuccess = (authData) => {
    // console.log('Firebase auth response: ', authData)

    return {
        type: actionTypes.AUTH_SUCCESS,
        tokenId: authData.idToken,
        userId: authData.localId,
    }
}
export const authFail = (err) => {
    // console.log(error.response.data.error)

    return {
        type: actionTypes.AUTH_FAIL,
        error: err,
    }
}
export const authLogout = () => {
    localStorage.removeItem('tokenId')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')

    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return (dispatch) => {
        setTimeout(() => {
            dispatch(authLogout())
        }, expirationTime * 1000)
    }
}

export const auth = (email, password, signupFlag) => {
    const authPayload = {
        email: email,
        password: password,
        returnSecureToken: true,
    }
    const url = signupFlag
        ? 'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyBBTVBGwT2FalFwlqdvDXi_Fo_nIZvsJPQ'
        : 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBBTVBGwT2FalFwlqdvDXi_Fo_nIZvsJPQ'

    return (dispatch) => {
        dispatch(authStart())
        axios
            .post(url, authPayload)
            .then((response) => {
                const expirationDate = new Date(
                    new Date().getTime() + response.data.expiresIn * 1000
                )
                localStorage.setItem('tokenId', response.data.idToken)
                localStorage.setItem('expirationDate', expirationDate)
                localStorage.setItem('userId', response.data.localId)
                dispatch(authSuccess(response.data))
                dispatch(checkAuthTimeout(response.data.expiresIn))
            })
            .catch((error) => {
                dispatch(authFail(error.response.data.error))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path,
    }
}

export const authCheckState = () => {
    return (dispatch) => {
        const tokenId = localStorage.getItem('tokenId')
        if (!tokenId) {
            dispatch(authLogout())
        } else {
            const expirationDate = new Date(
                localStorage.getItem('expirationDate')
            )
            if (expirationDate <= new Date()) {
                dispatch(authLogout())
            } else {
                const userId = localStorage.getItem('userId')
                dispatch(authSuccess({ idToken: tokenId, localId: userId }))
                dispatch(
                    checkAuthTimeout(
                        (expirationDate.getTime() - new Date().getTime()) / 1000
                    )
                )
            }
        }
    }
}
