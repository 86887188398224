import * as actionTypes from './actionTypes'
import axios from '../../axios-orders'

export const purchaseBurgerSuccess = (id, orderData) => {
    return {
        type: actionTypes.PURCHASE_BURGER_SUCCESS,
        orderId: id,
        orderData: orderData,
    }
}
export const purchaseBurgerFail = (error) => {
    return {
        type: actionTypes.PURCHASE_BURGER_FAIL,
        error: error,
    }
}
export const purchaseBurgerStart = () => {
    return {
        type: actionTypes.PURCHASE_BURGER_START,
    }
}
export const purchaseBurger = (idToken, orderData) => {
    return (dispatch) => {
        dispatch(purchaseBurgerStart())
        axios
            // .post('/orders.json', orderData)
            .post('/orders.json?auth=' + idToken, orderData)

            .then((response) => {
                dispatch(purchaseBurgerSuccess(response.data.name, orderData))

                // this.setState({ loading: false })
                // this.props.history.push('/')
                // this.props.onReset()
            })
            .catch((error) => {
                // console.log(error.toJSON())
                // console.log(error.JSON.stringify())

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data)
                    // console.log(error.response.status)
                    // console.log(error.response.headers)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }

                dispatch(purchaseBurgerFail(error))

                // this.setState({ loading: false })
            })
    }
}

export const purchaseInit = () => {
    return {
        type: actionTypes.PURCHASE_INIT,
    }
}

export const fetchOrdersStart = () => {
    return {
        type: actionTypes.FETCH_ORDERS_START,
    }
}
export const fetchOrdersSuccess = (beOrders) => {
    return {
        type: actionTypes.FETCH_ORDERS_SUCCESS,
        orders: beOrders,
    }
}
export const fetchOrdersFail = (err) => {
    return {
        type: actionTypes.FETCH_ORDERS_FAIL,
        error: err,
    }
}
export const fetchOrders = (idToken, userId) => {
    return (dispatch) => {
        const queryParams =
            '?auth=' + idToken + '&orderBy="userId"&equalTo="' + userId + '"'
        dispatch(fetchOrdersStart())
        axios
            .get('/orders.json' + queryParams)
            .then((res) => {
                const fetchedOrders = []
                for (let key in res.data) {
                    fetchedOrders.push({ ...res.data[key], id: key })
                }

                dispatch(fetchOrdersSuccess(fetchedOrders))
                // console.log(this.state.orders)
            })
            .catch((error) => {
                // console.log(error.JSON.stringify())

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data)
                    // console.log(error.response.status)
                    // console.log(error.response.headers)
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }

                dispatch(fetchOrdersFail(error))

                // this.setState({ loading: false })
            })
    }
}
