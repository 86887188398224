import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import burgerBuilderReducer from './store/reducers/burgerBuilder'
import orderReducer from './store/reducers/order'
import authReducer from './store/reducers/auth'

import { composeWithDevTools } from 'remote-redux-devtools'
import reduxThunk from 'redux-thunk'

// import devToolsEnhancer from 'remote-redux-devtools'

import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const rootReducer = combineReducers({
    bb: burgerBuilderReducer,
    ord: orderReducer,
    auth: authReducer,
})

const composeEnhancers = composeWithDevTools({
    realtime: true,
    name: 'Simple Redux',
    hostname: 'localhost',
    port: 3001, // the port your remotedev server is running at
})

// Use following code to disable redux devtools when in production environment ...
// Check on github remote-redux-devtools to see how to disable for prod env.
// const composeEnhancers =
//     process.env.NODE_ENV === 'development'
//         ? composeWithDevTools({
//               realtime: true,
//               name: 'Simple Redux',
//               hostname: 'localhost',
//               port: 3001, // the port your remotedev server is running at
//           })
//         : null

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(reduxThunk))
)

const app = (
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
reportWebVitals()
