import React from "react";

import classes from "./Backdrop.module.css";

// const backdrop = (props) => (
//     props.show ? <div className={classes.Backdrop} onClick={props.clicked}></div> : null
// );

const backdrop = ({ show, clicked }) =>
  show ? <div className={classes.Backdrop} onClick={clicked}></div> : null;

export default backdrop;
