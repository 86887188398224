import React from 'react'
import classes from './CheckoutSummary.module.css'
import Burger from '../../Burger/Burger'
import Button from '../../UI/Button/Button'

const CheckoutSummary = (props) => {
    return (
        <div className={classes.CheckoutSummary}>
            <p className={classes.InfoMsg}>Checkout Summary</p>
            <div>
                <Burger ingredients={props.ingredients} />
                <p className={classes.InfoMsg} style={{ textAlign: 'center' }}>
                    Good choice ... Yumm
                </p>
                <Button btnType='Danger' clicked={props.checkoutCancelled}>
                    Cancel
                </Button>
                <Button btnType='Success' clicked={props.checkoutContinued}>
                    Continue
                </Button>
            </div>
        </div>
    )
}

export default CheckoutSummary
