export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    }
}

export const checkValidity = (value, rules) => {
    let isValid = true

    if (rules.required) {
        isValid = value.trim() !== '' && isValid
    }

    if (rules.regexp) {
        if (rules.searchFlag) {
            isValid = value.search(rules.regexp) === -1 && isValid
        } else {
            isValid = rules.regexp.test(value) && isValid
        }
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    return isValid
}
