import React from 'react'
import classes from './Order.module.css'

const Order = (props) => {
    const ingredients = []

    let classArray = [classes.Order]
    if (props.selected) {
        classArray.push(classes.Selected)
    }
    for (let ingredientName in props.ingredients) {
        ingredients.push({
            name: ingredientName,
            quantity: props.ingredients[ingredientName],
        })
    }

    const ingredientOutput = ingredients.map((ig) => {
        return (
            <span key={ig.name} className={classes.Ingredient}>
                {ig.name}: {ig.quantity},
            </span>
        )
    })
    return (
        // <div className={classes.Order} onClick={props.clicked}>
        <div className={classArray.join(' ')} onClick={props.clicked}>
            <h4 style={{ display: 'inline', fontWeight: 500 }}>Ingredients:</h4>
            {ingredientOutput}
            <p>
                Price:{' '}
                <strong style={{ color: 'blue' }}>
                    USD {props.price.toFixed(2)}
                </strong>
            </p>
            <p style={{ color: 'green' }}>{props.date}</p>
        </div>
    )
}

export default Order
