import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import CheckoutSummary from '../../components/Order/CheckoutSummary/CheckoutSummary'
import ContactData from './ContactData/ContactData'

class Checkout extends Component {
    checkoutCancelledHandler = () => {
        this.props.history.replace('/')
        // this.props.history.goBack()
    }

    checkoutContinuedHandler = () => {
        this.props.history.replace('/checkout/contact-data')
    }

    render() {
        let summary = <Redirect to='/' />

        if (this.props.ingr) {
            const purchasedRedirect = this.props.purchased ? (
                <Redirect to='/' />
            ) : null

            summary = (
                <div>
                    <h1 style={{ fontWeight: 500, textAlign: 'center' }}>
                        Checkout
                    </h1>
                    <hr />
                    {purchasedRedirect}
                    <CheckoutSummary
                        ingredients={this.props.ingr}
                        checkoutCancelled={this.checkoutCancelledHandler}
                        checkoutContinued={this.checkoutContinuedHandler}
                    />
                    <Route
                        path={this.props.match.path + '/contact-data'}
                        component={ContactData}
                    />
                </div>
            )
        }
        return summary
    }
}

const mapStateToProps = (state) => {
    return {
        ingr: state.bb.ingredients,
        purchased: state.ord.purchased,
    }
}

export default connect(mapStateToProps)(Checkout)
