import React, { Component } from 'react'
import { connect } from 'react-redux'

import classes from './ContactData.module.css'
import Button from '../../../components/UI/Button/Button'
import axios from '../../../axios-orders'
import withErrorHandler from '../../../hoc/withErrorHandler/withErrorHandler'
import Spinner from '../../../components/UI/Spinner/Spinner'
import Input from '../../../components/UI/Input/Input'
import * as actions from '../../../store/actions/index'

import Modal from '../../../components/UI/Modal/Modal'
import { updateObject, checkValidity } from '../../../shared/utility'

class ContactData extends Component {
    state = {
        orderForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Firstname Lastname',
                    label: 'Name',
                },
                value: '',
                validation: {
                    required: true,
                    regexp: /\d/,
                    searchFlag: true,
                },
                valid: false,
                touched: false,
            },
            street: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Address',
                    label: 'Address',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
            },
            postCode: {
                elementType: 'input',
                elementConfig: {
                    type: 'number',
                    placeholder: 'Post code',
                    label: 'Post code',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 3,
                    maxLength: 8,
                },
                valid: false,
                touched: false,
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Country',
                    label: 'Country',
                },
                value: '',
                validation: {
                    required: true,
                    regexp: /\d/,
                    searchFlag: true,
                },
                valid: false,
                touched: false,
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'email@example.com',
                    label: 'Email',
                },
                value: '',
                validation: {
                    required: true,
                    regexp: /^[A-Z0-9+_.-]+@[A-Z0-9.-]+[.][A-Z]{2,3}$/i,
                    searchFlag: false,
                },
                valid: false,
                touched: false,
            },
            deliveryMethod: {
                elementType: 'select',
                elementConfig: {
                    placeholder: 'Delivery method',
                    label: 'Delivery method',
                    options: [
                        { value: 'fastest', displayValue: 'Fastest' },
                        { value: 'cheapest', displayValue: 'Cheapest' },
                    ],
                },
                value: 'fastest',
                validation: {},
                valid: true,
                touched: false,
            },
        },
        formIsValid: false,
        // loading: false,
        show: true,
    }

    orderHandler = (event) => {
        // alert('You continue!');
        event.preventDefault()
        // console.log(this.props)

        let timeStamp = new Date()
        // this.setState({ loading: true })

        const formData = {}
        for (let index in this.state.orderForm) {
            formData[index] = this.state.orderForm[index].value
        }

        const order = {
            ingredients: this.props.ingr,
            price: this.props.price,
            orderData: formData,
            orderDate: timeStamp.toString(),
            userId: this.props.userId,
        }

        this.props.onOrderBurger(this.props.authToken, order)
    }

    inputChangedHandler(event, inputIdentifier) {
        const updatedFormElement = updateObject(
            this.state.orderForm[inputIdentifier],
            {
                value: event.target.value,
                valid: checkValidity(
                    event.target.value,
                    this.state.orderForm[inputIdentifier].validation
                ),
                touched: true,
            }
        )

        const updatedOrderForm = updateObject(this.state.orderForm, {
            [inputIdentifier]: updatedFormElement,
        })

        let formDataValid = true
        for (let index in updatedOrderForm) {
            formDataValid = updatedOrderForm[index].valid && formDataValid
        }

        this.setState({
            orderForm: updatedOrderForm,
            formIsValid: formDataValid,
        })
    }

    orderCancelledHandler = () => {
        this.setState({ show: false })
        this.props.history.push('/checkout')
    }

    render() {
        const formElementsArray = []

        for (let index in this.state.orderForm) {
            formElementsArray.push({
                id: index,
                config: this.state.orderForm[index],
            })
        }

        let form = (
            <form onSubmit={this.orderHandler}>
                {formElementsArray.map((mapIndex) => (
                    <Input
                        key={mapIndex.id}
                        elementType={mapIndex.config.elementType}
                        elementConfig={mapIndex.config.elementConfig}
                        value={mapIndex.config.value}
                        invalid={!mapIndex.config.valid}
                        shouldValidate={mapIndex.config.validation}
                        touched={mapIndex.config.touched}
                        changed={(event) =>
                            this.inputChangedHandler(event, mapIndex.id)
                        }
                        label={mapIndex.config.elementConfig.label}
                    />
                ))}
                <Button btnType='Success' disabled={!this.state.formIsValid}>
                    Order
                </Button>
            </form>
        )

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div className={classes.ContactData}>
                <Modal
                    show={this.state.show}
                    modalClosed={this.orderCancelledHandler}>
                    {/* <p className={classes.InfoMsg}>Contact Data</p> */}
                    <h4>Contact details please ...</h4>
                    {form}
                    {/* <Route path='/checkout' component={Checkout} /> */}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ingr: state.bb.ingredients,
        price: state.bb.totalPrice,
        loading: state.ord.loading,
        authToken: state.auth.tokenId,
        userId: state.auth.userId,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOrderBurger: (token, orderInfo) =>
            dispatch(actions.purchaseBurger(token, orderInfo)),

        onReset: () => dispatch(actions.resetAll()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withErrorHandler(ContactData, axios))
